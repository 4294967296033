import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit"
import axios from "axios"

export const getSacksInStation = createAsyncThunk(
  "stationTask/getSacksInStation",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_KEY}/Stations/getDriverSacksSumByStation/${params.stationId}`,
        {
          headers: {
            Authorization: `Bearer ${params.token}`,
          },
        }
      )
      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const storageSacksSlice = createSlice({
  name: "stationTask",
  initialState: {
    loading: false,
    storageSacksSum: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSacksInStation.pending, (state) => {
        state.loading = true
      })
      .addCase(getSacksInStation.fulfilled, (state, { payload }) => {
        state.loading = false
        state.storageSacksSum = payload
      })
      .addCase(getSacksInStation.rejected, (state, { payload }) => {
        state.loading = false
      })
  },
})

export const { clearStationTaskData } = storageSacksSlice.actions
export default storageSacksSlice.reducer
