import { useDispatch, useSelector } from "react-redux"
import React, { useState, useEffect } from "react"
import { getSacksInStation } from "./storageSacksSlice"
import "./storageSacks.scss"

export const StorageSacks = (props) => {
  const { selectedStation } = useSelector((state) => state.stations)
  const { userloginData } = useSelector((state) => state.login)
  const { storageSacksSum } = useSelector((state) => state.storageSacks)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      getSacksInStation({
        token: userloginData.token,
        stationId: selectedStation?.id,
      })
    )
  }, [])

  return (
    <div className="storage-sacks">
      <div>שקים בתחנה: {storageSacksSum || 0}</div>
    </div>
  )
}
