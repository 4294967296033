import React, { useState, useEffect, useRef } from "react"
import { useSelector, useDispatch, shallowEqual } from "react-redux"
import {
  getOrdersByStation,
  setShowOrders,
  clearOrdersData,
  getOrdersTypes,
  getAccountTypes,
} from "./ordersSlice.js"
import { StationTask } from "./stationTask/stationTask"
import {
  getNearbyStation,
  clearNearbyStation,
} from "../stations/stationsSlice.js"
import markerStar from "../../assets/images/markerStar.png"
import pickup from "../../assets/images/pickup.svg"
import dropOff from "../../assets/images/drop_off.svg"
import { SingleOrder } from "./singleOrder/singleOrder"
import { Spinner } from "../../features/common/spinner/Spinner.js"
import { StorageSacks } from "./storageSacks/storageSacks"
import {
  getStationTasksByStation,
  clearStationTaskData,
} from "./stationTask/stationTaskSlice.js"
import "./orders.scss"

export function Orders() {
  const dispatch = useDispatch()
  const { selectedStation, nearbyStation } = useSelector(
    (state) => state.stations
  )
  const { taskReportedSuccess, stationTasks } = useSelector(
    (state) => state.stationTask
  )
  const { startDayTime, currentLanguage } = useSelector((state) => state.main)
  const { showOrders, ordersList, orderTypes, accountTypes, loading } =
    useSelector((state) => state.orders)
  const { userloginData } = useSelector((state) => state.login)
  const [hasFetchedData, setHasFetchedData] = useState(false)

  useEffect(() => {
    if (taskReportedSuccess) {
      dispatch(
        getStationTasksByStation({
          stationId: selectedStation.id,
          token: userloginData.token,
        })
      )
    }
  }, [taskReportedSuccess])

  useEffect(() => {
    if (!hasFetchedData && selectedStation && showOrders) {
      dispatch(getOrdersTypes({ token: userloginData.token }))
      dispatch(getAccountTypes({ token: userloginData.token }))
      dispatch(
        getStationTasksByStation({
          stationId: selectedStation.id,
          token: userloginData.token,
        })
      )
      dispatch(
        getOrdersByStation({
          stationId: selectedStation.id,
          token: userloginData.token,
          startDayTime: startDayTime,
        })
      )
      if (selectedStation?.nearByStationId) {
        dispatch(
          getNearbyStation({
            token: userloginData.token,
            stationId: selectedStation.nearByStationId,
          })
        )
      }
      setHasFetchedData(true)
    }
  }, [selectedStation, showOrders, hasFetchedData])

  const renderPickupOrders = () => {
    const ordersForPickup = ordersList
      .filter((order) => order.orderStatus === 0 || order.orderStatus === 13)
      .map((order, index) => (
        <SingleOrder
          key={index}
          orderData={order}
          index={index}
          orderTypes={orderTypes}
          accountTypes={accountTypes}
        />
      ))

    if (ordersForPickup.length === 0) {
      return <div className="orders-list-empty">אין הזמנות לקחת מהלוקר</div>
    }

    return ordersForPickup
  }

  const renderReturnOrders = () => {
    const ordersForReturn = ordersList
      .filter((order) => order.orderStatus === 3)
      .map((order, index) => (
        <SingleOrder
          key={index}
          orderData={order}
          index={index}
          orderTypes={orderTypes}
          accountTypes={accountTypes}
        />
      ))

    if (ordersForReturn.length === 0) {
      return <div className="orders-list-empty">אין הזמנות להשאיר בלוקר</div>
    }

    return ordersForReturn
  }

  const nearbyStationView = () => {
    const { stationNumber, branch } = nearbyStation
    if (currentLanguage == "He") {
      return (
        <div className="nearby-station">
          <div className="nearby-text">תחנה חלופית:</div>
          <div className="station-name">
            {stationNumber + " " + branch?.branchName}
          </div>
          <div className="station-address">{branch?.branchDisplayAddress}</div>
        </div>
      )
    } else {
      return (
        <div className="nearby-station eng">
          <div className="nearby-text">Alternative station:</div>
          <div className="station-name">
            {stationNumber + " " + branch?.branchName_en}
          </div>
          <div className="station-address">{branch?.address_en}</div>
        </div>
      )
    }
  }

  const staionDetailsView = () => {
    if (currentLanguage == "He") {
      return (
        <div className="station-details">
          <div className="station-name">
            {selectedStation.stationNumber + " " + selectedStation.name}
          </div>
          <div className="station-address">{selectedStation.address}</div>
        </div>
      )
    } else {
      return (
        <div className="station-details">
          <div className="station-name">
            {selectedStation.stationNumber + " " + selectedStation.name_en}
          </div>
          <div className="station-address">{selectedStation.address_en}</div>
        </div>
      )
    }
  }

  return (
    <div className="orders-wrapper">
      <div className="orders-overlay">
        <div className="orders-modal">
          <div className="header">
            <div
              className="btn-close"
              onClick={() => dispatch(setShowOrders(false))}
            >
              סגירה
            </div>
            <div className="title">רשימת חבילות</div>
          </div>
          <div className="station-info">
            <img alt="star" src={markerStar} className="star-icon" />
            <div className="adresses">
              <div>{staionDetailsView()}</div>
              {nearbyStation ? nearbyStationView() : null}
            </div>
          </div>
          <StorageSacks />
          <div className="content">
            {stationTasks?.length > 0 ? (
              <StationTask tasks={stationTasks} />
            ) : null}
            <div className="orders-section">
              <section className="orders-pickup">
                <div className="operation-text">
                  <img alt="pickup icon" src={pickup} />
                  <div>חבילות לקחת מהלוקר</div>
                </div>
                <div className="orders-list"> {renderPickupOrders()}</div>
              </section>
              <section className="orders-return">
                <div className="operation-text">
                  <img alt="dropOff icon" src={dropOff} />
                  <div>חבילות להשאיר בלוקר</div>
                </div>
                <div className="orders-list"> {renderReturnOrders()}</div>
              </section>
            </div>
          </div>
        </div>
      </div>
      {loading ? <Spinner /> : null}
    </div>
  )
}
